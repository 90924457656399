.modal-mask {
    position: fixed;
    z-index: 1099;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    min-width: 320px;

    /* Модификатор, чтобы попап разместить на десктопе. */
    &--relative {
        @media (--tablet-lg-min) {
            position: relative;
            background-color: transparent;
            z-index: 1;

            .modal-wrapper {
                overflow: visible;
            }

            .modal-container {
                box-shadow: none;
            }
        }
    }
}

.modal-wrapper {
    min-width: 320px;
    display: block;
    width: auto;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    @media (--tablet-lg) {
        width: 100%;
    }

    @media (--tablet) {
        height: 100%;
    }

    &--fullscreen {
        width: 100%;
        height: 100%;
    }
}

.modal-container {
    width: 300px;
    margin: 0 auto;
    background-color: #fff;
    /* border-radius: 4px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-header.popup__header {
    border-bottom: none;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(0);
    transform: scale(0);
}

.popup {
    min-width: 320px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 820px;
    max-width: 100%;
    /* border-radius: 8px; */

    &--image-preview {
        max-height: 100vh;
    }

    &--filters {
        @media (--tablet-lg-min) {
            background: transparent;
        }

        @media (--tablet-lg) {
            width: 100%;
        }

        .popup__close {
            @media (--tablet-lg-min) {
                display: none;
            }
        }
    }

    &--city-selection {
        overflow: hidden;
        height: 100%;
        @media (--tablet-min) {
            height: 600px;
        }
    }

    &--auth {
        width: 480px;
    }

    &--sm {
        width: 480px;
    }

    @media (--tablet) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        min-height: 100%;
        /* border-radius: 0; */
    }

    &--wide {
        width: 900px;

        @media (--tablet) {
            width: 100%;
        }
    }

    &--fullscreen,
    &--fixed {
        width: 100%;
        min-height: 100%;
    }

    &--aside {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 450px;
        height: 100%;

        @media (--tablet) {
            width: 100%;
        }
    }

    &--dropdown {
        width: 440px;
        text-align: left;
    }

    &--text {
        /* Здесь задаются стили для тегов и базовых классов, чтобы можно было вставлять контент из админки. */
        h3 {
            margin: calc(var(--gs) * 4) 0 calc(var(--gs) * 2);
            font-weight: bold;
        }

        p {
            margin: 0 0 calc(var(--gs) * 3);
        }

        strong {
            font-weight: bold;
        }
    }

    &__close {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 44px;
        height: 44px;
        color: var(--cl-grey);
        transition: all $time ease;
        cursor: pointer;
        background: white;
        border: none;

        &:hover {
            fill: black;
        }

        .icon {
            pointer-events: none;
        }
    }

    &__body,
    &__footer {
        width: 100%;
        padding: calc(var(--gs) * 6) calc(var(--gs) * 7) calc(var(--gs) * 7);
        .popup--fullscreen & {
            /* padding: calc(var(--gs) * 5) calc(var(--gs) * 2); */
        }

        @media (--mobile-lg) {
            padding: calc(var(--gs) * 4) calc(var(--gs) * 3);
            margin: 0;
        }
    }

    &__body {
        flex-grow: 1;
        .popup--city-selection & {
            height: 100%;
            overflow: hidden;
        }

        .popup--image-preview & {
            display: flex;
            flex-grow: 1;
        }

        @media (--tablet) {
            display: flex;
            flex-grow: 1;
        }
    }

    &__footer {
        background: var(--cl-elem-grey);

        @media (--tablet) {
            align-self: flex-end;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    &__hl {
        @media (--tablet) {
            padding-right: calc(var(--gs) * 4);
        }
    }

    /* .modal-wrapper--fullscreen & {
        border-radius: 0;
    } */
}
